.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0;
  overflow: hidden;
  user-select: none;
  z-index: 1001;
  opacity: 0;
  transition: opacity 150ms linear 0ms, height 0ms 150ms;

  &.visible {
    height: 100vh;
    opacity: 1;
    transition: opacity 200ms linear 0ms, height 0ms 0ms;
  }
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
  max-height: calc(100vh - 96px);
  max-width: calc(100vw - 16px);
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.25),
              0 4px 12px rgba(0,0,0,0.2),
              0 9px 24px rgba(0,0,0,0.15);

  opacity: 0;
  transform: translate3d(0,60px,0);
  transition: opacity 100ms linear 0ms, transform 300ms ease-out 0ms;

  &.multiStep {
    transition: opacity 100ms linear 0ms,
                transform 300ms ease-out 0ms
                width 0ms 300ms,
                height 0ms 300ms;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: inherit;
    background-color: var(--card-background);
    opacity: 0.75;
  }

  @media (min-width: 900px) and (min-height: 900px) {
    max-height: calc(100vh - 48px);
    max-width: calc(100vw - 16px);
  }
}

.heading {
  width: 100%;
  max-width: calc(100vw - 16px);
  background-color: rgba(255,255,255,0.035);
  text-align: center;
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.03em;
  color: var(--colors-white);
  height: 58px;
  flex-shrink: 0;
  line-height: 57px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.35);
  border-bottom: 1px solid var(--colors-grey7);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.175);
}


.step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  flex-basis: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(-50%,-50%,0);
  transition: opacity 0ms linear 0ms;

  &.active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 150ms linear 300ms;
  }
}

.container.visible .content {
  opacity: 1;
  transform: perspective(1000px) scale3d(1,1,1) rotateX(0deg) translate3d(0,0,0);
  transition: opacity 300ms linear 300ms, transform 300ms ease 300ms;

  &.multiStep {
    transition: opacity 300ms linear 300ms,
                transform 300ms ease 300ms,
                width 300ms ease-in-out 0ms,
                height 300ms ease-in-out 0ms;
  }
}
